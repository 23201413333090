(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/oddset-kambi/assets/javascripts/init-client.js') >= 0) return;  svs.modules.push('/modules/oddset-kambi/assets/javascripts/init-client.js');
"use strict";


const {
  createParametersObj,
  getCustomerId,
  getAuthorizationCode
} = svs.oddset_kambi.utils;
const {
  getNotificationsHandler
} = svs.oddset_kambi.components.clientEventsHandler;
const {
  getExpandedStatistics
} = svs.oddset_kambi.components.expandedStatisticsHandler;
const {
  handledErrors
} = svs.oddset_kambi.components.customizedFeedbackHandler.constants;
const isLoggedIn = svs.core.userSession.hasRole(svs.core.userSession.roles.PLAYER);

const initCustomerSettings = helpers => {
  const CustomerSettings = function () {
    this.routeRoot = svs.core.data.baseUrl;
    this.showFooterSettingsLink = isLoggedIn;
    this.customizedBetslipFeedbackTypes = handledErrors;
    if (svs.core.detect.feature('ft-kambi-client-login-url-override')) {
      this.loginUrl = "".concat(svs.core.urlMapping.get('loginHome'), "?returnUrl=").concat(encodeURIComponent(svs.core.urlMapping.get('oddsetHome')));
    }
  };

  CustomerSettings.prototype.showEventStatistics = eventId => getExpandedStatistics(eventId);
  CustomerSettings.prototype.notification = getNotificationsHandler(helpers);
  window.customerSettings = new CustomerSettings();
};

const initClient = async helpers => {
  const {
    customerIdPrefix,
    kambiEnv: environment,
    kambiCdnUrl
  } = svs.oddset_kambi.data;
  const customerId = await getCustomerId();
  const authorizationCode = await getAuthorizationCode();

  window._kc = createParametersObj({
    authorizationCode,
    customerId,
    customerIdPrefix,
    environment
  });
  initCustomerSettings(helpers);
  const script = document.createElement('script'); 
  script.src = kambiCdnUrl;
  script.async = true;
  document.body.appendChild(script);
};
setGlobal('svs.oddset_kambi.initClient', initClient);

 })(window);