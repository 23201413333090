(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/oddset-kambi/assets/javascripts/pages/page-play.js') >= 0) return;  svs.modules.push('/modules/oddset-kambi/assets/javascripts/pages/page-play.js');
"use strict";


const {
  useEffect,
  useContext,
  useState
} = React;
const initClient = svs.oddset_kambi.initClient;
const {
  MaintenanceContext
} = svs.oddset_kambi.components.maintenance_context;
const {
  NavContext
} = svs.oddset_kambi.components.nav_context;
const {
  SportradarStatisticsIframe
} = svs.oddset_kambi.components.sportradar_statistics;
const widgetEvents = svs.oddset_kambi.components.widgetEvents;
const {
  KambiWidgetEvents
} = svs.oddset_kambi.components.common.constants;
const {
  BetsContext
} = svs.oddset_kambi.components.bets_context;
const {
  setupWapiSubscription
} = svs.oddset_kambi.components.wapiIntegration;
const PlayPage = () => {
  const {
    setKambiMaintenance
  } = useContext(MaintenanceContext);
  const {
    setActivePathname
  } = useContext(NavContext);
  const [betradarId, setBetradarId] = useState(null);
  const {
    refreshPendingBets,
    refreshPendingBetsThrottled,
    setNumPendingBets
  } = useContext(BetsContext);
  useEffect(() => {
    if (!window.hasInitialized) {
      initClient({
        setKambiMaintenance,
        setActivePathname,
        refreshPendingBets,
        refreshPendingBetsThrottled,
        setBetradarId
      });
      window.hasInitialized = true;
    }
    const kambiContainerElement = document.querySelector('#KambiBC');
    kambiContainerElement.classList.remove('kambi-hidden');
    return () => {
      kambiContainerElement.classList.add('kambi-hidden');
    };
  }, [setKambiMaintenance, setActivePathname, refreshPendingBets, refreshPendingBetsThrottled]);
  useEffect(() => {
    const handleSetBetradarId = event => {
      setBetradarId(event.detail.betRadarId);
    };
    widgetEvents.addEventListener(KambiWidgetEvents.SET_BETRADAR_ID, handleSetBetradarId);
    return () => {
      widgetEvents.removeEventListener(KambiWidgetEvents.SET_BETRADAR_ID, handleSetBetradarId);
    };
  }, []);
  useEffect(() => {
    let subscription;
    let hasUnmounted = false;
    const setupSubscription = async () => {
      const wapi = await window.KambiWidget.ready;
      if (hasUnmounted) {
        return;
      }
      subscription = setupWapiSubscription({
        setNumPendingBets
      }, wapi);
    };
    setupSubscription();
    return () => {
      hasUnmounted = true;
      if (subscription) {
        subscription.unsubscribe();
      }
    };
  }, [setNumPendingBets]);
  if (betradarId) {
    return React.createElement(SportradarStatisticsIframe, {
      betradarId: betradarId,
      closeModal: () => setBetradarId(null)
    });
  }
  return null; 
};
setGlobal('svs.oddset_kambi.pages.PlayPage', PlayPage);

 })(window);