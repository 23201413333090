(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/oddset-kambi/assets/javascripts/pages/page-game-guide.js') >= 0) return;  svs.modules.push('/modules/oddset-kambi/assets/javascripts/pages/page-game-guide.js');
"use strict";


const {
  GameGuideMenu,
  StartPage,
  ArticlePage,
  Cubes,
  constants: {
    gameGuideRoutes
  }
} = svs.oddset_kambi.components.game_guide;
const {
  BrowserRouter: Router,
  Route,
  Switch
} = ReactRouterDOM;
const GameGuidePage = () => React.createElement(Router, {
  basename: "".concat(svs.core.data.baseUrl, "/spelguide")
}, React.createElement("div", {
  className: "oddset-game-guide f-content"
}, React.createElement(GameGuideMenu, null), React.createElement("main", {
  className: "oddset-game-guide__content"
}, React.createElement(Switch, null, gameGuideRoutes.map(route => {
  if (route.path === '/') {
    return React.createElement(Route, {
      exact: true,
      key: route.path,
      path: "/",
      render: () => React.createElement(React.Fragment, null, React.createElement(Cubes, null), React.createElement(StartPage, null))
    });
  }
  return React.createElement(Route, {
    key: route.path,
    path: route.path,
    render: () => React.createElement(ArticlePage, {
      articleId: route.article
    })
  });
})))));
setGlobal('svs.oddset_kambi.pages.GameGuidePage', GameGuidePage);

 })(window);